import { yupResolver } from '@hookform/resolvers/yup';
import { loadStripe } from '@stripe/stripe-js';
import React, {
  useEffect, useMemo, useRef
} from 'react';
import { createPortal } from 'react-dom';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button, Loading, Radio, Typography
} from 'tfc-components';

import { StripeCheckoutFormRef } from './StripeCheckoutForm';

import Icon from 'components/atoms/Icon';
import Container from 'components/organisms/Container';
import Modal from 'components/organisms/Modal';
import PromotionalCodeInput from 'components/organisms/PromotionalCodeInput';
import returnErrorMessages from 'services/common/errors';
import {
  cancelOrderPaypalByIdService,
  getPayPalService,
  paymentMoMoService,
  stripePaymentService
} from 'services/orders';
import {
  MoMoParamsTypes, PayPalLinkParamsTypes
} from 'services/orders/types';
import { getProductDetailService, getProductPromotionService } from 'services/products';
import { getProfileAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  COLORS, CONSTANT_ROUTES, LOCAL_STORAGE, PRODUCT_DURATION_NAME
} from 'utils/constants';
import mapModifiers, {
  addAlpha, currencyFormat, dateCalculate, dateFormat,
  daysUntil
} from 'utils/functions';
import useSchema from 'utils/schemas';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stripePromise = loadStripe(
  'pk_test_51Q8SLMRqjja03BsFnX7wnBW6vXTJAWnMvfuXK1ju9TcxTzugVNbOb8dch0Ft7smKbWG5llfDpScvKLGjqGc0Aaoq00PZAEAZZa'
);

type PromotionFormTypes = {
  promotionCode?: string
};

const Payment: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const language = useAppSelector((state) => state.systems.language);
  const { t } = useTranslation();
  const [method, setMethod] = React.useState<string>('paypal');
  const [paymentPrice, setPaymentPrice] = React.useState<{
    paymentPrice?: number;
    paymentPriceVND?: number;
    nextRecurringDate?: string;
    durationType?: number;
    freeDuration?: number;
    code?: string;
  }>({});
  const [searchParams] = useSearchParams();
  const { paymentSchema } = useSchema();
  const id = searchParams.get('id');
  const subscriptionId = searchParams.get('subscription_id');
  const profile = useAppSelector((state) => state.auth.profile);
  const dispatch = useAppDispatch();

  const methodForm = useForm<PromotionFormTypes>({
    defaultValues: {
      promotionCode: '',
    },
    resolver: yupResolver(paymentSchema),
    mode: 'onChange'
  });

  // STATES
  const [orderErrorModal, setOrderErrorModal] = React.useState(false);
  const [paymentErrorModal, setPaymentErrorModal] = React.useState({
    isOpen: false,
    message: '',
  });

  // REFS
  const stripeRef = useRef<StripeCheckoutFormRef>(null);

  // QUERIES
  const { data: productData, isLoading: loadingMain } = useQuery(
    ['product', id],
    () => (id ? getProductDetailService(id) : undefined),
    {
      enabled: !!id,
    }
  );

  const { data: productPromotionData, isLoading: loadingProductPromotion } = useQuery(
    ['productPromotion', id],
    () => (id ? getProductPromotionService(id) : undefined),
    {
      enabled: !!id,
    }
  );

  const { mutate: paymentMoMoMutate, isLoading: isLoadingURLMomo } = useMutation(
    'payment-momo',
    (params: MoMoParamsTypes) => paymentMoMoService(params),
    {
      onSuccess: (data) => {
        window.location.replace(data.payUrl);
        localStorage.removeItem(LOCAL_STORAGE.ORDER_ID);
        dispatch(getProfileAction());
      },
      onError: (error: ErrorResponse[]) => {
        if (error.length > 0) {
          error.forEach((e) => setPaymentErrorModal({
            isOpen: true,
            message: t(returnErrorMessages(e)),
          }));
        }
        localStorage.removeItem(LOCAL_STORAGE.ORDER_ID);
      },
    }
  );

  const { mutate: paymentStripeMutate, isLoading: isLoadingStripe } = useMutation(
    'payment-stripe',
    stripePaymentService,
    {
      onSuccess: (data) => {
        navigate(
          `${!language || language === 'vi' ? '' : `/${language}`}${CONSTANT_ROUTES.PAYMENT_RESULT[language ?? 'vi']
          }?stripeCode=${data.status.value}`
        );
        localStorage.removeItem(LOCAL_STORAGE.ORDER_ID);
      },
      onError: (error: ErrorResponse[]) => {
        if (error.length > 0) {
          error.forEach((e) => setPaymentErrorModal({
            isOpen: true,
            message: t(returnErrorMessages(e)),
          }));
        }
        localStorage.removeItem(LOCAL_STORAGE.ORDER_ID);
      },
    }
  );

  const { mutate: paymentPaypalMutate, isLoading: isLoadingURLPaypal } = useMutation(
    'payment-paypal',
    (params: PayPalLinkParamsTypes) => getPayPalService(params),
    {
      onSuccess: (res) => {
        dispatch(getProfileAction());
        if (res?.link) {
          window.open(res.link, '_self');
        }
      },
      onError: (error: ErrorResponse[]) => {
        if (error.length > 0) {
          if (error[0].code === 'CodeRevokedOrDeleted') {
            toast.error(t('message.codeNotValid'));
          } else {
            error.forEach((e) => setPaymentErrorModal({
              isOpen: true,
              message: t(returnErrorMessages(e)),
            }));
          }
        }
        localStorage.removeItem(LOCAL_STORAGE.ORDER_ID);
      },
    }
  );

  const { mutate: cancelOrderPaypalByIdMutate, isLoading: loadingCancelPaypal } = useMutation(
    'cancel-payment-paypal',
    (subscriptionIdParam: string) => cancelOrderPaypalByIdService(subscriptionIdParam),
    {
      onError: () => {
        toast.error(t('system.errorTitle'));
      },
      onSettled: () => {
        const url = new URL(window.location.href);
        url.searchParams.delete('subscription_id');
        url.searchParams.delete('ba_token');
        url.searchParams.delete('token');
        window.history.replaceState({}, '', url.toString());
      }
    }
  );

  const formattedPlanData = useMemo(() => {
    const currency = method === 'momo' ? 'priceVND' : 'price';
    const hasDiscount = productData?.productPrice && productData?.productPrice.length > 1;
    const defaultPrice = productData?.productPrice.find((p) => p.isDefault)?.[currency] || 0;
    const discountPrice = hasDiscount
      ? productData?.productPrice.find((p) => !p.isDefault)?.[currency]
      : 0;

    return {
      ...productData,
      price: Number(hasDiscount ? discountPrice : defaultPrice),
      oldPrice: hasDiscount ? defaultPrice : undefined,
      title: language === 'vi' ? 'Thanh toán' : 'Payment',
      description: language === 'vi'
      ? 'Evol luôn ở đây để giúp bạn kiểm tra độ chính xác của câu trả lời, cung cấp phản hồi chi tiết, các giải pháp chính xác và đề xuất các bài tập nâng cao hơn để cải thiện quá trình học tập của bạn.'
      : 'Evol is always here for you to check the accuracy of your answers, provide detailed feedback, correct solutions, and suggest similar or more advanced exercises to enhance your learning process',
    };
  }, [method, productData, language]);

  // FUNCTIONS
  const handlePayment = async () => {
    const redirectUrl = `${window.location.origin}${!language || language === 'vi' ? '' : `/${language}`}${CONSTANT_ROUTES.PAYMENT_RESULT[language ?? 'vi']}`;
    const returnUrl = `${origin}${!language || language === 'vi' ? '' : `/${language}`}${CONSTANT_ROUTES.PAYMENT_RESULT[language ?? 'vi']}`;

    switch (method) {
      case 'momo':
        paymentMoMoMutate({
          redirectUrl,
          orderId: Number(id),
        });
        break;
      case 'paypal':
        {
          const { href } = window.location;
          paymentPaypalMutate({
            productId: Number(id),
            cancelUrl: href,
            returnUrl,
            ...(paymentPrice.code && { promotionCode: paymentPrice.code }),
          });
        }
        break;
      case 'credit':
        {
          const token = await stripeRef.current?.handleSubmit();
          if (token && formattedPlanData.price) {
            paymentStripeMutate({
              orderId: Number(id),
              token,
              amount: formattedPlanData.price,
            });
          }
        }
        break;
      default:
        break;
    }
  };

  const durationRecurring = useMemo(() => {
    if (paymentPrice.nextRecurringDate) {
      return daysUntil(paymentPrice.nextRecurringDate);
    }

    return null;
  }, [paymentPrice.nextRecurringDate]);

  // EFFECTS
  useEffect(() => {
    if (location.state) {
      methodForm.setValue('promotionCode', location.state.code);
      methodForm.setError('promotionCode', { message: location.state.error });
    }
  }, [location.state, methodForm]);

  useEffect(() => {
    const pageUrl = localStorage.getItem(LOCAL_STORAGE.PAGE_URL);
    if (productData && productData.nameUnique === profile?.productData?.nameUnique) {
      toast.error(t('message.upgraedProduct'));
      if (pageUrl) {
        localStorage.removeItem(LOCAL_STORAGE.PAGE_URL);
        navigate(`/${pageUrl}`);
      } else {
        navigate('/');
      }
    }
  }, [navigate, productData, profile, t]);

  useEffect(() => {
    if (subscriptionId) {
      cancelOrderPaypalByIdMutate(subscriptionId);
    }
  }, [cancelOrderPaypalByIdMutate, subscriptionId]);

  const handleApply = () => {
    const promotionCode = methodForm.watch('promotionCode');
    const validPromotionCode = productPromotionData?.find((item) => item.code === promotionCode);
    if (validPromotionCode) {
      setPaymentPrice({
        paymentPrice: validPromotionCode?.paymentPrice,
        paymentPriceVND: validPromotionCode?.paymentPriceVND,
        nextRecurringDate: validPromotionCode?.nextRecurringDate || '',
        durationType: validPromotionCode?.durationType,
        freeDuration: validPromotionCode?.freeDuration,
        code: validPromotionCode?.code || '',
      });
      toast.success(t('message.appliedCodeSuccess'));
      methodForm.clearErrors('promotionCode');
    } else {
      methodForm.setError('promotionCode', { message: t('message.invalidCode') });
    }
  };

  const finalPrice = useMemo(() => {
    if (method === 'momo') {
      return paymentPrice.paymentPriceVND;
    } if (method === 'paypal') {
      return paymentPrice.paymentPrice;
    }
    return null;
  }, [method, paymentPrice]);

  const discountPriceCal = useMemo(() => {
    if (finalPrice) {
      return formattedPlanData.price;
    }
    return null;
  }, [finalPrice, formattedPlanData]);

  if (loadingMain || loadingProductPromotion || loadingCancelPaypal) {
    return (
      <div className="loading-fullscreen">
        <Loading.CircleDashed width={32} color={COLORS.deepCarotOrange} />
      </div>
    );
  }

  if (!productData) return null;

  return (
    <div
      className="p-payment"
      style={{ '--cta-color': addAlpha(productData?.contentColor, 0.3) } as React.CSSProperties}
    >
      <FormProvider<PromotionFormTypes> {...methodForm}>
        <Container fluid>
          <div className="p-payment_top">
            <h1
              className="p-payment_title color-indigo fs-60x64"
              dangerouslySetInnerHTML={{ __html: formattedPlanData.title }}
            />
            <div
              className="p-payment_description fs-16x24"
              dangerouslySetInnerHTML={{ __html: formattedPlanData.description }}
            />
            <div className="p-payment_plan">
              <Typography.Text fontweight="600" extendClasses="fs-18x24">
                {formattedPlanData.translations?.[language || 'vi']?.name || ''}
              </Typography.Text>
            </div>
            <div
              className="p-payment_plan_detail"
              style={{
                backgroundColor: addAlpha(productData.contentColor, 0.12),
              }}
            >
              <div className="p-payment_plan_item">
                <Typography.Text fontweight="600" extendClasses="color-jet fs-16x20">
                  {t('system.total')}
                </Typography.Text>
                <Typography.Text
                  fontweight="600"
                  extendClasses="fs-16x20 color-giants-orange p-payment_plan_price"
                >
                  {`${currencyFormat(finalPrice || formattedPlanData.price, method === 'momo' ? 'VND' : 'USD')}/${t(
                    PRODUCT_DURATION_NAME[formattedPlanData.duration ?? 30]
                  )}`}
                </Typography.Text>
              </div>
              {/* <div className="p-payment_plan_item">
              <Typography.Text extendClasses="color-jet fs-14x16">
                {t('system.nextBilling')}
              </Typography.Text>
              <Typography.Text extendClasses="color-jet fs-14x16">
                {dateFormat(dateCalculate(new Date(), formattedPlanData.duration ?? 30), language)}
              </Typography.Text>
            </div> */}
              <div className="p-payment_plan_description fs-14x16">
                <ul>
                  <li>
                    {`${t('system.nextBillingOn')} ${dateFormat(dateCalculate(durationRecurring || formattedPlanData.duration || 30), language)}`}
                    {paymentPrice.durationType && (
                      <span>
                        {` (${t('system.getFree')} ${paymentPrice.freeDuration || 0} ${paymentPrice.durationType === 1 ? t('system.date') : t('system.month')} ${t('system.free')})`}
                      </span>
                    )}
                  </li>
                  <li>{t('system.cancelAnytime')}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="p-payment_options">
            {/* <Radio
              name="method"
              value="momo"
              extendClasses={mapModifiers('p-payment_radio', method === 'momo' && 'active')}
              onChange={() => setMethod('momo')}
            >
              <div className="p-payment_option">
                <div className="p-payment_option_holder" />
                <Typography.Text
                  fontweight="600"
                  extendClasses="color-smoky-black fs-16x24 p-payment_option_name"
                >
                  {t('system.momoWallet')}
                </Typography.Text>
                <Icon iconName="momo" size="48" />
              </div>
            </Radio> */}
            {/* <Radio
            name="method"
            value="credit"
            extendClasses={mapModifiers(
              'p-payment_radio',
              method === 'credit' && ['active', 'expand']
            )}
            onChange={() => setMethod('credit')}
          >
            <div className="p-payment_option">
              <div className="p-payment_option_holder" />
              <Typography.Text
                fontweight="600"
                extendClasses="color-smoky-black fs-16x24 p-payment_option_name"
              >
                Stripe
              </Typography.Text>
              <Icon iconName="visa" size="48" />
            </div>
            <Elements stripe={stripePromise}>
              <StripeCheckoutForm ref={stripeRef} />
            </Elements>
          </Radio> */}
            <Radio
              name="method"
              value="paypal"
              extendClasses={mapModifiers('p-payment_radio', method === 'paypal' && 'active')}
              onChange={() => setMethod('paypal')}
            >
              <div className="p-payment_option">
                <div className="p-payment_option_holder" />
                <Typography.Text
                  fontweight="600"
                  extendClasses="color-smoky-black fs-16x24 p-payment_option_name"
                >
                  PayPal
                </Typography.Text>
                <Icon iconName="paypal" size="48" />
              </div>
            </Radio>
            <div className="p-payment_promo">
              <Controller
                name="promotionCode"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <PromotionalCodeInput
                      id="promoCode"
                      name="promoCode"
                      isPayment
                      value={value}
                      disabledButton={value.length !== 12
                      || Object.keys(methodForm.formState.errors).length > 0}
                      onChange={onChange}
                      handleApply={handleApply}
                    />
                    {error && (
                      <p
                        style={{
                          color: '#c61e1e',
                          marginLeft: '8px',
                          marginTop: '8px',
                          fontSize: '14px',
                        }}
                      >
                        {error.message}
                      </p>
                    )}
                  </>
                )}
              />
            </div>
            <div className="p-payment_confirm">
              <div className="p-payment_total">
                <Typography.Text extendClasses="fs-16x24 color-black" fontweight="600">
                  {t('system.totalBill')}
                  {': '}
                  <Typography.Text
                    type="span"
                    fontweight="600"
                    extendClasses="fs-18x24 color-deep-carrot-orange"
                  >
                    {discountPriceCal && (
                      <Typography.Text
                        type="span"
                        extendClasses="color-ash-grey fs-16x20 p-payment_totalText"
                        textStyle="lineThrough"
                      >
                        {`${currencyFormat(
                          discountPriceCal,
                          method === 'momo' ? 'VND' : 'USD'
                        )}/${t(PRODUCT_DURATION_NAME[formattedPlanData.duration ?? '30'])}`}
                      </Typography.Text>
                    )}
                    <span className="p-payment_finalPrice">
                      {`${currencyFormat(
                        finalPrice || formattedPlanData.price,
                        method === 'momo' ? 'VND' : 'USD'
                      )}/${t(PRODUCT_DURATION_NAME[formattedPlanData.duration ?? '30'])} `}
                    </span>
                  </Typography.Text>
                </Typography.Text>
              </div>
              <Button
                extendClasses="p-payment_confirm_button"
                onClick={handlePayment}
                loading={isLoadingURLMomo || isLoadingStripe || isLoadingURLPaypal}
                loadingIndicator={(
                  <Loading.CircleDashed
                    width={24}
                    color={COLORS.deepCarotOrange}
                  />
                )}
              >
                <Icon iconName="protect" size="24" />
                <Typography.Text extendClasses="color-white fs-16x24" fontweight="600">
                  {t('system.continuePayment')}
                </Typography.Text>
              </Button>
            </div>
          </div>
        </Container>
      </FormProvider>
      {orderErrorModal
        && createPortal(
          <Modal onClose={() => setOrderErrorModal(false)}>
            <Modal.Header>{t('system.errorTitle')}</Modal.Header>
            <Modal.Body>
              <Typography.Text extendClasses="fs-16x24 text-center">
                {t('system.errorMessage')}
              </Typography.Text>
            </Modal.Body>
            <Modal.Footer>
              <Button
                extendClasses="btn-secondary btn-h44 btn-wfull p-payment_modalBtn"
                onClick={() => setOrderErrorModal(false)}
              >
                <Typography.Text fontweight="600">{t('system.close')}</Typography.Text>
              </Button>
            </Modal.Footer>
          </Modal>,
          document.body
        )}
      {paymentErrorModal.isOpen
        && createPortal(
          <Modal
            onClose={() => setPaymentErrorModal({
              isOpen: false,
              message: '',
            })}
          >
            <Modal.Header>{t('system.paymentErrorTitle')}</Modal.Header>
            <Modal.Body>
              <Typography.Text extendClasses="fs-16x24 text-center">
                {paymentErrorModal.message || t('system.paymentErrorMessage')}
              </Typography.Text>
            </Modal.Body>
            <Modal.Footer>
              <Button
                extendClasses="btn-secondary btn-h44 btn-wfull p-payment_modalBtn"
                onClick={() => setPaymentErrorModal({
                  isOpen: false,
                  message: '',
                })}
              >
                <Typography.Text fontweight="600">{t('system.close')}</Typography.Text>
              </Button>
            </Modal.Footer>
          </Modal>,
          document.body
        )}
    </div>
  );
};

export default Payment;
